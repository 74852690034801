var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-note-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{attrs:{"title":_vm.backButtonTitle},on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('filter-setting-layout',{attrs:{"filter-model":_vm.module.model},scopedSlots:_vm._u([{key:"tab-main",fn:function(){return [_c('a-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('config-field',{attrs:{"title":_vm.$t('filter_title_advanced'),"mini-help-message":_vm.$t('module_video_note_filter_handler_module_advanced_settings_mini_help_message')}})]},proxy:true}])},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'video_note_min_duaration',
              'min': 0,
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 5,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'video_note_max_duaration',
              'min': 0,
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 60,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'video_note_min_file_size',
              'min': 0,
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 1,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'video_note_max_file_size',
              'min': 0,
              'hasAnchor': true,
              'settingWrapper': {
                customiseValue: 10000000,
                hasFieldButton: true,
                disabledValue: 0
              },
              'disabled': _vm.disabled
            }
          }}})],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.journal_moderation_warn,"ban-field":_vm.module.model.journal_moderation_ban,"disabled":_vm.disabled},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)}}}),_c('filter-extra-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-regular",fn:function(){return [_c('filter-common-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-new",fn:function(){return [_c('filter-new-users-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType,"disabled":_vm.disabled},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }